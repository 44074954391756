<template>
  <div class="m-user-edit">
    <b-notification
      v-if="savingError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('users.savingError') }}
      <div class="b-be-errors" v-if="errorsFromBe.length > 0">
        <hr />
        <ul>
          <li :key="key" v-for="(error, key) in errorsFromBe">
            {{ error }}
          </li>
        </ul>
      </div>
    </b-notification>
    <b-loading :active.sync="loading"></b-loading>
    <h1 class="title is-1">{{ heading }}</h1>
    <div class="columns">
      <div class="column">
        <b-field :label="$t('users.company')">
          <b-select v-model="company" :placeholder="$t('users.companyPlaceholder')" :loading="loadingCompanies">
            <option v-for="company in companiesSelect" :value="company._id" :key="company._id">
              {{ company.name }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="b-google-login">
          <strong>{{ $t('users.assignedGoogleLogin') }}:</strong>
          {{ $t(authProviderGoogle ? 'generic.yes' : 'generic.no') }}
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <div class="container ra-form-section">
          <div class="columns ra-columns-form">
            <div class="column is-half">
              <b-field :label="$t('users.nameFirst')">
                <b-input v-model="nameFirst" :placeholder="$t('users.nameFirstPlaceholder')"></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field :label="$t('users.nameLast')">
                <b-input v-model="nameLast" :placeholder="$t('users.nameLastPlaceholder')"></b-input>
              </b-field>
            </div>
          </div>

          <b-field v-if="authProviderGoogle">
            <div class="b-google-login-warning">{{ $t('users.googleLoginMailChangeWarning') }}</div>
          </b-field>

          <b-field :label="$t('users.email')">
            <b-input
              :disabled="!localAccountCreateOrEdit"
              v-model="email"
              type="email"
              autocomplete="off"
              maxlength="30"
              :placeholder="$t('users.emailPlaceholder')"
              icon="envelope"
            ></b-input>
          </b-field>

          <b-field :label="$t('users.password')" v-if="localAccountCreateOrEdit">
            <b-input
              v-model="password"
              type="password"
              autocomplete="new-password"
              icon="user-secret"
              password-reveal
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="column is-half">
        <div class="container ra-form-section">
          <b-field :label="$t('users.position')">
            <b-input v-model="position" :placeholder="$t('users.positionPlaceholder')"></b-input>
          </b-field>
          <b-field :label="$t('users.workAddress')">
            <b-input v-model="workAddress" :placeholder="$t('users.workAddressPlaceholder')"></b-input>
          </b-field>
          <b-field :label="$t('users.phone')">
            <b-input v-model="phone" :placeholder="$t('users.phonePlaceholder')"></b-input>
          </b-field>
          <b-field :label="$t('users.linkedIn')">
            <b-input v-model="linkedIn" :placeholder="$t('users.linkedInPlaceholder')"></b-input>
          </b-field>
        </div>
      </div>
    </div>

    <div class="columns ra-form-section">
      <div class="column is-half" v-if="localAccountCreateOrEdit">
        <div class="container">
          <h2 class="title is-4">{{ $t('generic.accessRightsHeading') }}</h2>
          <div class="b-roles">
            <div class="b-role" v-for="role in rolesRegistry" :key="role._id">
              <div class="columns">
                <div class="column is-half">
                  <b-checkbox v-model="roles" :native-value="role._id">
                    {{ role.name }}
                  </b-checkbox>
                </div>
                <div class="column">
                  {{ role.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <h2 class="title is-4">{{ $t('generic.otherHeading') }}</h2>
        <b-field>
          <b-checkbox v-model="boarded">
            {{ $t('users.boarded') }}
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="mailNotifications">
            {{ $t('users.mailNotifications') }}
          </b-checkbox>
        </b-field>
        <b-field v-if="authProviderLocal">
          <b-checkbox v-model="resetAccount">
            {{ $t('users.resetAccount') }}
          </b-checkbox>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="field is-grouped">
          <div class="control">
            <b-button @click="cancel" type="is-secondary">{{ $t('generic.cancelButton') }}</b-button>
          </div>
          <div class="control">
            <b-button :disabled="loading" @click="dispatch" type="is-primary" :loading="saving">{{
              $t('generic.saveButton')
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as namespaces from '../../../store/namespaces';
import * as actions from '../../../store/actions';
import clientUsers from '../../../api/users';
import clientCompanies from '../../../api/companies';
import logger from '../../../utils/logger';

export default {
  name: 'UserEdit',
  props: {
    userId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      companiesList: [],
      loading: false,
      loadingCompanies: false,
      loadingError: false,
      saving: false,
      savingError: false,
      errorsFromBe: [],
      // User data properties
      authProviderLocal: null,
      authProviderGoogle: null,
      company: '',
      nameFirst: '',
      nameLast: '',
      email: '',
      password: '',
      phone: '',
      linkedIn: '',
      boarded: false,
      mailNotifications: false,
      resetAccount: false,
      position: '',
      workAddress: '',
      roles: []
    };
  },
  computed: {
    companiesSelect() {
      let list = [
        {
          _id: '',
          name: ' - ' + this.$t('users.noCompany') + ' - '
        }
      ];
      return list.concat(this.companiesList);
    },
    rolesRegistry() {
      if (this.$store.state.system.registries) {
        return this.$store.state.system.registries.roles;
      }
      return [];
    },
    isEdit() {
      return !!this.userId;
    },
    heading() {
      if (this.isEdit) {
        return this.$t('users.editUserHeading');
      } else {
        return this.$t('users.createUserHeading');
      }
    },
    localAccountCreateOrEdit() {
      return !this.isEdit || this.authProviderLocal;
    }
  },
  methods: {
    createDataPayload() {
      const data = {
        company: this.company || null,
        nameFirst: this.nameFirst,
        nameLast: this.nameLast,
        email: this.email,
        phone: this.phone,
        position: this.position,
        workAddress: this.workAddress,
        linkedIn: this.linkedIn,
        boarded: this.boarded,
        mailNotifications: this.mailNotifications,
        resetAccount: this.resetAccount,
        roles: this.roles || []
      };
      if (this.password) {
        data.password = this.password;
      }
      if (this.isEdit) {
        return { id: this.userId, data };
      } else {
        return data;
      }
    },
    loadCompanies() {
      this.loadingCompanies = true;
      return clientCompanies
        .list()
        .then(companies => {
          this.companiesList = companies;
        })
        .finally(() => {
          this.loadingCompanies = false;
        });
    },
    loadUser() {
      if (!this.userId) {
        return false;
      }
      this.loading = true;
      this.loadingError = false;
      clientUsers
        .get(this.userId)
        .then(user => {
          this.authProviderLocal = user.authProviderLocal;
          this.authProviderGoogle = user.authProviderGoogle;
          this.email = user.email;
          this.roles = user.roles || [];
          this.company = user.company;
          this.nameFirst = user.nameFirst;
          this.nameLast = user.nameLast;
          this.phone = user.phone;
          this.linkedIn = user.linkedIn;
          this.position = user.position;
          this.workAddress = user.workAddress;
          this.boarded = user.boarded;
          this.mailNotifications = user.mailNotifications;
          this.resetAccount = user.resetAccount;
        })
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dispatch() {
      this.saving = true;
      this.savingError = false;
      this.errorsFromBe = [];
      let action;
      action = namespaces.USERS + (this.isEdit ? actions.EDIT_USER : actions.ADD_USER);

      let payload = this.createDataPayload();
      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.$router.replace({ name: 'users-list' });
        })
        .catch(error => {
          this.savingError = true;
          this.errorsFromBe = error.response.data.errors;
          logger.error(error);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancel() {
      this.company = '';
      this.nameFirst = '';
      this.nameLast = '';
      this.email = '';
      this.phone = '';
      this.linkedIn = '';
      this.boarded = false;
      this.mailNotifications = false;
      this.resetAccount = false;
      this.roles = [];
      this.workAddress = '';
      this.password = '';
      this.$router.go(-1);
    }
  },
  mounted() {
    this.loadUser();
    this.loadCompanies();
  },
  watch: {
    userId() {
      this.loadUser();
    }
  }
};
</script>
